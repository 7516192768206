import { createCart } from '@features/api/shopify';
import type { CartLineAddRequest } from '@features/api/shopify/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { queryKeys } from 'settings/queryKeys';

function useUpdateCart() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: queryKeys.cart.update(),
		mutationFn: async (data: Array<CartLineAddRequest>) => {
			const cart = await createCart(data);
			if (!cart) throw new Error('Cart creation failed');

			return cart;
		},
		onMutate: async (data) => {
			await queryClient.cancelQueries({ queryKey: queryKeys.cart.base });

			const previousCart = queryClient.getQueryData(queryKeys.cart.base);

			return { previousCart };
		},
		onSuccess: (data) => {
			Cookies.set('cartId', data.id);

			queryClient.setQueryData(queryKeys.cart.base, data);
		},
		onError: (err, lineItems, context) => {
			console.error(err);
			if (!context?.previousCart) return;

			queryClient.setQueryData(queryKeys.cart.base, context.previousCart);
		},
	});
}

export default useUpdateCart;
