import { addOrReplaceItems } from '../data/actions';
import { useFormState, useFormStatus } from 'react-dom';

import { IconCheck, IconChevronRight } from '@tabler/icons-react';
import i18next from '../../../i18n';
import { Button } from '@features/webshop/shared/ui/Button';
import LoadingDots from '@features/webshop/shared/ui/LoadingDots';
import { BtnSizeEnum, BtnVariantEnum, BtnColorEnum } from '@features/api/client';

function SubmitButton({
	availableForSale,
	isAlreadyInCart,
	selectedVariantId,
	labelSuffix,
}: {
	availableForSale: boolean;
	isAlreadyInCart: boolean;
	selectedVariantId: string | undefined;
	labelSuffix?: React.ReactNode;
}) {
	const { pending } = useFormStatus();

	if (!availableForSale) {
		return (
			<Button variant={BtnVariantEnum.Primary} disabled className="w-full">
				{i18next.t('Currently unavailable')}
			</Button>
		);
	}

	if (!selectedVariantId) {
		return (
			<Button variant={BtnVariantEnum.Primary} disabled className="w-full">
				<span className="flex items-center gap-1 xs:gap-2">
					{i18next.t('Choose')} {labelSuffix}
				</span>
				<IconChevronRight className="h-6" strokeWidth={3} />
			</Button>
		);
	}

	if (isAlreadyInCart) {
		return (
			<Button variant={BtnVariantEnum.Primary} disabled className="w-full">
				<span className="flex items-center gap-1 xs:gap-2">
					{i18next.t('Choosen')} {labelSuffix}
				</span>
				<IconCheck className="h-6" strokeWidth={3} />
			</Button>
		);
	}

	return (
		<Button
			className="w-full"
			variant={BtnVariantEnum.Primary}
			size={BtnSizeEnum.Large}
			color={BtnColorEnum.Red}
			disabled={pending}
			onClick={(e: React.FormEvent<HTMLButtonElement>) => {
				if (pending) e.preventDefault();
			}}>
			<span className="flex items-center gap-2">
				{i18next.t('Choose')} {labelSuffix}
			</span>
			{pending ? (
				<LoadingDots className="bg-white" />
			) : (
				<IconChevronRight className="h-6" strokeWidth={3} />
			)}
		</Button>
	);
}

function AddToCartButton({
	selectedVariantId,
	availableForSale,
	isAlreadyInCart,
	selectedSellingPlanId,
	checkoutUrl,
	labelSuffix,
}: {
	selectedVariantId?: string;
	availableForSale: boolean;
	isAlreadyInCart: boolean;
	selectedSellingPlanId?: string;
	checkoutUrl?: string;
	labelSuffix?: React.ReactNode;
}) {
	const addItemToCartAndGoToCheckout = async (
		prevState: any,
		{
			selectedVariantId,
			selectedSellingPlanId,
		}: {
			selectedVariantId: string | undefined;
			selectedSellingPlanId: string | undefined;
		}
	) => {
		const { cart, errorMessage } = await addOrReplaceItems(prevState, [
			{ selectedVariantId, selectedSellingPlanId },
		]);
		const url = cart?.checkoutUrl || checkoutUrl;
		if (url && !errorMessage) window.location.href = url;

		return errorMessage;
	};

	const [errorMessage, formAction] = useFormState(addItemToCartAndGoToCheckout, null);
	const actionWithVariant = formAction.bind(null, { selectedVariantId, selectedSellingPlanId });

	return (
		<form className="flex w-full flex-col gap-2" action={actionWithVariant}>
			<SubmitButton
				availableForSale={availableForSale}
				selectedVariantId={selectedVariantId}
				isAlreadyInCart={isAlreadyInCart}
				labelSuffix={labelSuffix}
			/>
			<p aria-live="polite" className="sr-only" role="status">
				{errorMessage}
			</p>
			{errorMessage && <span className="text-destructive">Greška pri dodavanju u košaricu</span>}
		</form>
	);
}

export default AddToCartButton;
