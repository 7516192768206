'use client';

import React from 'react';
import HydrateAtoms from '../SubscriptionListing/state/HydrateAtoms';
import { selectedProductsAtom } from './state/selectedProductsAtom';

function ProductCardItemsWrapper({
	initalLineItemIds,
	children,
}: {
	initalLineItemIds: Array<string>;
	children: React.ReactNode;
}) {
	return (
		<HydrateAtoms atomValues={[[selectedProductsAtom, { ids: initalLineItemIds, isDirty: false }]]}>
			{children}
		</HydrateAtoms>
	);
}

export default ProductCardItemsWrapper;
