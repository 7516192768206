'use client';
import { type ProductList, type Product } from '@features/api/client';
import AddToCartButton from '@features/webshop/cart/ui/AddToCartButton';
import {
	formatMoneyWithAdjustment,
	formatSellingPlanRecurrencePeriodLabel,
} from '@features/shared/utils/formatMoney';
import i18next from 'i18next';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { type Product as ShopifyProductType } from '../../../../../api/shopify/types';
import { selectedSellingPlanAtom } from '../SubscriptionListing/state/sellingPlanAtom';
import { RadioGroup, RadioGroupItem } from '@features/webshop/shared/ui/RadioGroup';
import { cn } from '@features/shared/utils/utils';

type SubscriptionPriceInfoProps = {
	shopifyProduct: ShopifyProductType;
	cmsProduct: Product | ProductList | undefined;
	checkoutUrl?: string;
	cartError?: any;
};

function SubscriptionPriceInfo({
	shopifyProduct,
	cmsProduct,
	checkoutUrl,
	cartError,
}: SubscriptionPriceInfoProps) {
	const [selectedSellingPlan] = useAtom(selectedSellingPlanAtom);
	const priceAdjustment = selectedSellingPlan?.priceAdjustments[0]?.adjustmentValue;
	const minVariantPrice = formatMoneyWithAdjustment({
		...shopifyProduct.priceRange.minVariantPrice,
		adjustmentValue: priceAdjustment,
	});
	const maxVariantPrice = formatMoneyWithAdjustment({
		...shopifyProduct.priceRange.maxVariantPrice,
		adjustmentValue: priceAdjustment,
	});

	const [selectedVariantId, setSelectedVariantId] = useState<string>(shopifyProduct.variants[0]?.id || '');
	const selectedVariant = shopifyProduct.variants.find((variant) => variant.id === selectedVariantId);

	if (!cmsProduct || !shopifyProduct) return null;

	return (
		<div className="flex h-full w-full flex-col justify-between gap-16 xs:gap-20 sm:gap-24">
			<div className="flex flex-col gap-4">
				<div className="text-xl font-medium">{i18next.t('Choose class')}</div>
				<RadioGroup
					className="flex gap-4 xs:gap-5"
					value={selectedVariantId}
					onValueChange={setSelectedVariantId}>
					{shopifyProduct.variants.map((variant, index) => (
						<RadioGroupItem
							className={cn(
								'h-14 w-14 rounded-full border-[3px] border-solid border-white/50 bg-white/20 text-white shadow-[0_0_10px_0_rgba(0,0,0,0.10)] backdrop-blur-[2px] md:h-20 md:w-20',
								selectedVariantId === variant.id && 'border-white bg-white text-primary-blue'
							)}
							value={variant.id}
							key={variant.id}>
							<span className="translate-x-[2px] text-2xl font-black">{index + 1}.</span>
						</RadioGroupItem>
					))}
				</RadioGroup>
			</div>
			<div className="flex flex-col gap-4 self-stretch">
				<div className="items-ceter flex gap-2">
					<span className="text-6xl font-black leading-none md:text-[82px]">
						{minVariantPrice !== maxVariantPrice
							? `${minVariantPrice?.split(' ')[0]} - ${maxVariantPrice?.split(' ')[0]}`
							: maxVariantPrice?.split(' ')[0]}
					</span>
					<span className="flex flex-col justify-center">
						<span className="text-3xl font-extrabold sm:text-4xl md:text-5xl">
							{maxVariantPrice?.split(' ')[1]}
						</span>
						<span className="text-md text-base font-bold md:text-lg">
							/{formatSellingPlanRecurrencePeriodLabel(selectedSellingPlan)}
						</span>
					</span>
				</div>
				<AddToCartButton
					checkoutUrl={checkoutUrl}
					availableForSale={!cartError}
					selectedVariantId={selectedVariantId}
					labelSuffix={
						selectedVariant ? (
							<span className="text-md min-w-[100px] text-base font-regular md:text-xl">
								({selectedVariant?.title})
							</span>
						) : undefined
					}
					isAlreadyInCart={false}
					selectedSellingPlanId={selectedSellingPlan?.id}
				/>
			</div>
		</div>
	);
}

export default SubscriptionPriceInfo;
