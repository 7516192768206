import type { Cart } from '@features/api/shopify/types';
import { selectedProductsAtom } from '@features/webshop/landing-pages/ui/sections/ProductListing/state/selectedProductsAtom';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { isDeepEqual } from 'remeda';
import { useDebounceValue } from 'usehooks-ts';
import useGetCart from './useGetCart';
import useUpdateCart from './useUpdateCart';

function useUpdateCartOnChange({ initialCart }: { initialCart?: Cart }) {
	const query = useGetCart({ initialData: initialCart });
	const cart = query.data;

	const [selectedProducts, _] = useAtom(selectedProductsAtom);
	const [debouncedSelectedProducts] = useDebounceValue(selectedProducts, 200);

	const { mutate: updateCart } = useUpdateCart();

	useEffect(() => {
		if (!debouncedSelectedProducts.isDirty) return;
		const productIdsInCart = cart?.lines.map((line) => line.merchandise.product.id) || [];
		const areNewProductsAddedToCart = !isDeepEqual(productIdsInCart, debouncedSelectedProducts.ids);
		if (!areNewProductsAddedToCart) return;

		updateCart(debouncedSelectedProducts.ids.map((id) => ({ merchandiseId: id, quantity: 1 })));
	}, [debouncedSelectedProducts]);

	return query;
}

export default useUpdateCartOnChange;
